body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  height: 100vh;
	width: 100vw;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --grey: #494949;
  --light_grey: #7c7a7a;
  --primary: #ffef5c;
}

/* Scroll bar settings */
/* width */
::-webkit-scrollbar {
  width: 12px;
  background: none;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(1,1,1,1)
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202); 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
