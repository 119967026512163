/* global page styles */
h2 {
    font-weight: 80;
}
h1 {
    font-weight: 400;
}

#title_top {
    padding-top: 12%;
    display: flex;
    justify-content: center;
}

#animation-desc {
    font-size: 40pt; 
    margin: 0;
}

/* Styling the parallax's foreground (the maving section)
height, width and background color */
.parallax-foreground {
    width: 100%;
    height: auto;
    background-size: 100% 100%;
    background-color: rgb(0, 0, 0);  
    margin: auto;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}

.top {
    margin-top: 100px;
    height: 100%;
}
/*background moving dot canvas*/
#background-canvas {
    background: #0a0a0a;
    width: 100vw;
    height: 100vh;
    position: fixed;
}

.content_container {
    max-width: 1080px;
    width: 100%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 1000px;
    z-index: 5;
    color: #fff;
}

.canvas-div canvas {
    position: relative;
    width: 100%;
    height: 100%;
}


.section_1 {
    z-index: 1000;
}
.section_1 h1 {
    text-align: left;
    font-weight: 80;
    font-size: 60pt;
}

.section_2 {
    float: left;
    margin-top: 25%;
    font-size: x-large;
    margin-bottom: 10%;
}

.section_2 h3 {
    font-weight: 80;
}

.section_3 {
    float: left;
    font-size: x-large;
    display: contents;
    text-align: left;
}
.section_3 h1 {
    text-align: left;
    float: left;
}

.section_4 {
    float: left;
}

.section_4 h1 {
    font-size: 60pt;
    font-weight: 400;
}

.section_5 {
    float: left;
}
.section_5 h1 {
    font-size: 60pt;
}

.pie_section span {
    font-weight: 80;
}

.chart-container {
    display: flex;
    justify-content: center;
    height: 60vh;
    margin-top: 10%;
}

.roadmap-image {
    z-index: 200;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.team-container {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
}

.profile-card {
    position: relative;
    font-family: sans-serif;
    width: 220px;
    height: 220px;
    background: #fff;
    padding: 30px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
    margin: 0 25px;
    flex: 33%;
}

.profile-card:hover {
    border-radius: 10px;
    height: 280px;
}
.profile-card .img {
    position: relative;
    width: 100%;
    height: 100%;
    transition: .6s;
    z-index: 99;
}

.img img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
}

.profile-card:hover .img {
    transform: translateY(-60px);
}

.profile-card:hover img {
    border-radius: 10px;
}

.caption {
    text-align: center;
    transform: translateY(-120px);
    opacity: 0;
    transition: .6s;
}

.profile-card:hover .caption {
    opacity: 1;
}

.caption h3 {
    color: black;
    font-size: 21px;
    font-family: sans-serif;
}

.caption p {
    font-size: 15px;
    color: #0c52a1;
    font-family: sans-serif;
    margin: 2px 0 9px 0;
}

.caption .social-links a {
    color: #333;
    margin-right: 15px;
    font-size: 21px;
    transition: .6s;
}

.social-links a:hover {
    color: #0c52a1;
}

.profilepic {
    height: 215px;
    width: 220px;
}

/* CSS that should be displayed if width is equal to or less than 1080px goes here */
/* This overrides all desktop styles in mobile view (screen < 1080px) */
/* Some styles from this view are also eligible for small devices so it gets applied here to avoid duplicates with media query below */
@media only screen and (max-width: 1079px){
    .content_container {
        display: block;
        margin: auto;
    }
    .content_container h1 {
        font-size: 20pt;
        margin: auto;
    }
    .section_1 {
        align-items: center;
        margin-left: 0;
        padding-left: 0;
    }
    .title {
        justify-content: space-around
    }
    .title div h1 {
        margin-top: 3%;
    }

    #title_top #subtitle {
        margin-top: 10%;
        font-size: 25pt;
        text-align: left;
        padding-left: 2%;
        width: 100%;
    }
    #animation-desc {
        max-width: 1080px;
        width: 100%;
        font-size: 25pt; 
        text-align: left;
        padding: 2%;
        margin-left: 0;
        margin-top: 5%;
    }
    .section_2 div {
        padding: 10px;
        text-align: center;
    }

    .pie_section {
        text-align: center;
        padding: 10px;
    }

    .profile-card {
        margin-bottom: 10%;
        margin-left: auto;
        margin-right: auto;
    }
    .section_4 {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .section_4 span h1 {
        text-align: center;
        font-size: 25pt;
        padding: 50px;
    }
    .team-container {
        margin-top: 10%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
        margin-bottom: 800px;
    }

    #about {
        margin-left: 0;
    }
    .section_5 span h1 {
        text-align: center;
        font-size: 25pt;
        padding: 30px;
        font-weight: 200;
    }
    .footer {
        margin-top: 20% !important;
    }
}

/* CSS that should be displayed if width is equal to or less than 450px goes here */
/* This being the last overrides all desktop and tablet styles in mobile view (screen < 450px)*/
@media (max-width: 450px) {
    .parallax-background {
        max-width: 450px;
    }
    #title_top {
        height: 100vh;
        padding-top: 30px;
    }
    #title_top .main-title  {
        padding-top: 12%;
        font-size: 40pt;
    }
    #title_top #subtitle {
        margin-top: 20%;
        font-size: 17pt;
        text-align: center;
        padding-left: 0;
    }
    #background-canvas {
        width: 100%;
    }

    .animation-desc-container {
        padding: 10px;
        padding-top: 20%;
    }

    #animation-desc {
        max-width: 450px;
        font-size: 16pt; 
        text-align: center;
        margin: 0;
    }
    .profile-card {
        padding: 20px;
        margin-bottom: 20%;
    }

    .canvas-div canvas {
        padding-top: 50%;
        margin-top: 10%;
        max-width: 450px;
        width: 100%;
    }
        
    #cloud-canvas {
        margin-top: 0px;
        margin-left: 0;
    }
    .chart-container {
        height: 45vh;
    }
    .footer {
        margin-top: 80% !important;
    }
    .roadmap-image {
        width: 140%;
        margin-left: -20%;
    }
}
