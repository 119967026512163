.footer {
    position: sticky;
    width: 1080px;
    height: 360px; 
    color: white;
    margin: auto;
    margin-top: 15%;
    text-align: center;
}
.content_footer {
    max-width: 1080px;
    padding-top: 2%;
    padding-bottom: 3%;
    font-size: 24pt;
}
.content_footer a {
    color: white;
}
#send_email {
    text-decoration: none;
}
#send_email:hover {
    text-decoration: underline; 
}
.copyright {
    display: flex;
    justify-content: center;
    padding: 3%;
}

.icons {
    margin-top: 4%;
    margin-bottom: 3.5%;
}

.icons a {
    background-color: var(--primary);
    border-radius: 5px;
    font-size: 70px;
    margin-left: 5%;
    margin-right: 5%;
    color: black;
    text-align: center;
}

.icons a:hover {
    font-size: 60px;
}

@media screen and (max-width: 1079px) {
    .footer {
        width: 100%;
        height: 280px;
        margin-top: 85%;
        padding-top: 10%;
    }
    .content_footer {
        font-size: large;
        margin-bottom: 0;
    }
    .icons  {
        display: flexbox;
        margin: 4%;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .icons a {
        height: 20px;
        width: 20px;
        font-size: 40px;
    }
    .copyright {
        font-size: smaller;
        margin-bottom: 0;
    }
}
